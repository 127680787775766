<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card
        class="radius-card pb-3"
        :loading="loading"
        min-height="150px"
        style="overflow: hiden"
      >
        <div>
          <v-tabs
            v-model="tab"
            class="mb-5 rounded"
            background-color="purple lighten-5"
            color="purple"
            grow
          >
            <v-tab href="#tab-1"
              ><v-icon>mdi-book</v-icon><b>Admin Ebook</b></v-tab
            >
            <v-tab href="#tab-2"
              ><v-icon>mdi-book-multiple</v-icon><b>Pyscholog Ebook</b></v-tab
            >
            <v-tab href="#tab-3"
              ><v-icon>mdi-book-multiple</v-icon><b>Block Ebook</b></v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab" style="background: none">
            <v-tab-item value="tab-1">
              <ListEbookAdmin />
            </v-tab-item>
            <v-tab-item value="tab-2">
              <ListEbookUser />
            </v-tab-item>
            <v-tab-item value="tab-3">
              <ListEbookAdminBlock />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import ListEbookAdmin from "./listEbookAdmin.vue";
import ListEbookUser from "./listEbookUser.vue";
import ListEbookAdminBlock from "./listEbookAdminBlock.vue";
export default {
  name: "articleManagement",

  components: { ListEbookAdmin, ListEbookUser, ListEbookAdminBlock },

  computed: {},

  data() {
    return {
      tab: "tab-1"
    };
  },

  mounted() {},

  methods: {}
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
