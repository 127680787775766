var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"radius-card pb-3",attrs:{"loading":_vm.loading,"min-height":"150px"}},[_c('v-card-title',{staticClass:"ma-0 pa-3"},[_c('h3',[_vm._v("Ebook")]),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","color":"purple","dark":"","to":"/admin/create/Ebook"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Create Ebook")],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","single-line":"","solo":"","rounded":"","hide-details":""},on:{"keyup":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.ebook)?_c('div',{staticClass:"3"},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.ebook.data,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.perPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            arrowType: 'round',
            animation: 'fade',
            theme: 'light',
            maxWidth: 100
          }),expression:"{\n            arrow: true,\n            arrowType: 'round',\n            animation: 'fade',\n            theme: 'light',\n            maxWidth: 100\n          }"}],attrs:{"color":"blue","outlined":"","href":(_vm.env + "/ebook/upload/" + (item.user_id) + "/file/" + (item.file)),"content":"Download file","dark":"","rounded":""}},[_vm._v(_vm._s(item.file))])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.created_at).format("DD, MMMM, YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","depressed":"","outlined":"","color":"green","to":("/ebook/" + (item.id) + "/view")}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)}),_c('UpdateEbook',{attrs:{"editEbook":_vm.editEbook,"item":_vm.item},on:{"close":_vm.closeDialog}}),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.ebook.meta.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }