<template>
  <v-card class="radius-card pb-3" :loading="loading" min-height="150px">
    <v-card-title class="ma-0 pa-3">
      <h3>Ebook</h3>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        dense
        @keyup="fetchItems"
        single-line
        solo
        rounded
        hide-details
      ></v-text-field>
    </v-card-title>

    <div class="3" v-if="ebook">
      <v-data-table
        :headers="headers"
        :items="ebook.data"
        hide-default-footer
        :page.sync="page"
        :items-per-page="perPage"
        class="elevation-0 my-5"
      >
        <template v-slot:[`item.file`]="{ item }">
          <v-btn
            color="blue"
            outlined
            :href="`${env}/ebook/upload/${item.user_id}/file/${item.file}`"
            content="Download file"
            v-tippy="{
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100
            }"
            dark
            rounded
            >{{ item.file }}</v-btn
          >
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ $date(item.created_at).format("DD, MMMM, YYYY") }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              icon
              depressed
              outlined
              color="green"
              class="mr-2"
              :to="`/ebook/${item.id}/view`"
            >
              <v-icon dark small> mdi-eye </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <UpdateEbook
        @close="closeDialog"
        v-bind:editEbook="editEbook"
        v-bind:item="item"
      />
      <div class="text-center pa-3">
        <v-pagination
          v-model="page"
          circle
          @input="fetchItems"
          :length="ebook.meta.last_page"
        ></v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import UpdateEbook from "../Psycholog/Modal/editEbook";

export default {
  name: "articleManagement",

  components: {
    UpdateEbook
  },

  computed: {
    ...mapState({
      ebook: state => state.library.public_ebook,
      env: state => state.API_URL
    })
  },

  data() {
    return {
      dialogAdd: false,
      editEbook: false,
      item: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "TITLE", value: "judul" },
        { text: "AUTHOR", value: "author" },
        { text: "FILE", value: "file" },
        {
          text: "CREATED AT",
          value: `created_at`
        },
        { text: "ACTIONS", value: "actions" }
      ],
      page: 1,
      perPage: 5
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      //get all data  fucntion
      this.loading = true;
      this.$store
        .dispatch("library/publicEbook", {
          page: this.page,
          limit: this.perPage,
          find: this.search
        })
        .then(() => {
          this.loading = false;
        });
    },

    updateItem(item) {
      this.item = item;
      this.editEbook = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("library/deleteEbook", { id: item.id })
            .then(() => {
              this.fetchItems(); //if success refetch data all
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.editEbook = false; //set variable dialog to false
      this.fetchItems(); //and refetch data
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
